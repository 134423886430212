// File: ./src/pages/DashboardPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiBaseUrl } from '../config';
import { LoaderDark } from '../components/icons/LoaderDark';
import TrustedBy from '../components/pricing/TrustedBy';
import Logo from '../components/header/Logo';
import { isLoggedIn } from '../api/Auth';
import { openBillingPortal } from '../api/Billing';

function DashboardPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    (async () => {
      // First check if the user is logged in
      const isAuthenticated = await isLoggedIn();
      if (!isAuthenticated) {
        navigate('/login');
        return;
      }

      // If logged in, fetch user data
      try {
        const res = await fetch(`${apiBaseUrl}/get-user`, {
          method: 'GET',
          credentials: 'include',
        });
        if (!res.ok) {
          throw new Error('Failed to fetch user data');
        }
        const data = await res.json();
        if (data.error) {
          throw new Error(data.message || 'Error fetching user data');
        }
        setUserData(data);
      } catch (err) {
        console.error(err);
        setErrorMessage(err.message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [navigate]);

  const handleManageSubscription = async () => {
    if (!userData || !userData.subscription) return;
    if (userData.subscription.stripe_product !== 'Free') {
      try {
        const data = await openBillingPortal();
        if (data.url) {
          window.location.href = data.url;
        }
      } catch (error) {
        console.error('Billing portal error:', error.message);
        setErrorMessage(error.message);
      }
    } else {
      const stripeCustomerId = userData.subscription.stripe_customer_id || '';
      navigate(`/pricing?stripe_customer_id=${stripeCustomerId}&is_trialing=true`);
    }
  };
  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoaderDark />
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-6">
        <p className="text-red-500 text-lg">Error: {errorMessage}</p>
      </div>
    );
  }

  if (!userData) {
    return null; // or redirect
  }

  const { email, credits, subscription } = userData;
  const stripeProduct = subscription?.stripe_product || 'Free';
  const buttonLabel = stripeProduct !== 'Free' ? 'Manage Subscription' : 'Upgrade';

  return (
    <div className="sm:min-h-screen sm:flex">
      {/* Left column */}
      <div className="flex-1 flex flex-col justify-between bg-white">
        <div className="py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full">
          <Logo />
          <h1 className="mt-10 sm:mt-20 text-3xl font-extrabold text-black">
            Welcome to your Dashboard
          </h1>
          <p className="mt-4 text-lg">Logged in as: {email}</p>
          <p className="mt-2 text-lg">Credits: {credits}</p>
          <p className="mt-2 text-lg">Plan: {stripeProduct}</p>

          <div className="mt-8">
            <button
              onClick={handleManageSubscription}
              className="py-3 px-6 rounded-lg bg-cyan-600 text-white font-bold hover:bg-crminputs-dark-blue"
            >
              {buttonLabel}
            </button>
          </div>

          {errorMessage && (
            <p className="text-red-500 text-md pt-4">{errorMessage}</p>
          )}
        </div>
      </div>

      {/* Right column */}
      <div className="flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue flex-col px-8 py-16 sm:p-16">
        <TrustedBy darkMode={true} />
      </div>
    </div>
  );
}

export default DashboardPage;
