import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Logo from '../components/header/Logo';
import TrustedBy from '../components/pricing/TrustedBy';
import { apiBaseUrl } from '../config';
import { LoaderDark } from '../components/icons/LoaderDark';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../api/Auth';

// We'll interpret server error messages, which might contain HTML links
function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [done, setDone] = useState(false);

  const title = "Forgot Password - CRM Inputs";
  const description = "Enter your email to get a password reset link.";

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);

    try {
      const data = await forgotPassword(email);
      if (data.error) {
        // This includes the new error messages from server
        throw new Error(data.message || 'Something went wrong');
      }
      // If success => setDone(true)
      setDone(true);
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <div className="min-h-screen flex">
        {/* Left column */}
        <div className="flex-1 flex flex-col justify-between bg-white">
          <div className="py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full">
            <Logo />
            <h2 className="mt-10 sm:mt-20 text-3xl font-extrabold text-black">
              Forgot Password
            </h2>

            {done ? (
              <div className="mt-8">
                <p className="text-md text-gray-700 pb-8">
                  If your account exists, you'll receive an email with a link to reset your password (expires in 24 hours).
                </p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                {errorMessage && (
                  <div
                    className="text-red-500 py-4 rounded relative"
                    role="alert"
                    // Dangerously set if you want the link HTML to work
                    dangerouslySetInnerHTML={{ __html: errorMessage }}
                  />
                )}

                <div>
                  <label
                    htmlFor="email"
                    className="block text-lg font-default text-gray-800"
                  >
                    Email address
                  </label>
                  <div className="mt-1.5">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      placeholder="you@yourcompany.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="appearance-none block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 
                                 focus:outline-none focus:ring-crminputs-dark-blue  focus:border-crminputs-darkish-blue sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-auto flex justify-center py-4 px-8 rounded-lg hover:shadow-xl text-md font-bold text-white bg-cyan-600 
                               hover:bg-crminputs-dark-blue hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? (
                      <>
                        <LoaderDark />
                        Sending...
                      </>
                    ) : (
                      'Send Reset Link'
                    )}
                  </button>

                  <p className="text-md text-gray-500 pt-8">
                    <Link
                      to="/login"
                      className="hover:underline"
                    >
                      ← Back to login
                    </Link>
                  </p>
                </div>
              </form>
            )}
          </div>
        </div>

        {/* Right column */}
        <div className="flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue 
                        flex-col px-8 py-16 sm:p-16">
          <h3 className="text-white text-4xl font-bold pb-10">
            Stop doing CRM data entry.
          </h3>
          <img
            className="max-w-full sm:max-w-sm object-contain"
            src="/img/signup-hero.jpg"
            alt="CRM Inputs - LinkedIn Integrations for CRMs"
          />
          <h3 className="text-cyan-200 italic text-xl sm:text-2xl font-default pt-8 pb-16">
            1-Click LinkedIn to CRM integration ✨
          </h3>
          <TrustedBy darkMode={true} />
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordPage;
