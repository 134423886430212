
// Contains a comma (where applicable) and a dollar sign
export const FormatMoney = (price) => {
    return '$' + price.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function GetSoftwareSlug(){
    let urlParams = new URLSearchParams(window.location.search);
    let fragmentParams = new URLSearchParams(window.location.hash.substring(1));
    const softwareParam1 = urlParams.get('software');
    const softwareParam2 = fragmentParams.get('software');
    const softwareParam3 = localStorage.getItem('software');
    const softwareParam = softwareParam1 || softwareParam2 || softwareParam3;
    return softwareParam;
}