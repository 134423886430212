// File: ./src/components/signup/SignupForm.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import InputField from '../fields/InputField';
import SoftwareSelection from './SoftwareSelection';
import GoogleSignupButton from '../auth/GoogleSignupButton';
import posthog from 'posthog-js';
import { apiBaseUrl } from '../../config';
import { Loader } from '../icons/Loader';
import { GetURLRedirect } from '../../utils/Router';
import { passwordStrength } from 'check-password-strength';
import { GetUTMParams } from '../../utils/UTM';
import { signup } from '../../api/Auth';

function SignupForm({ software }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    software: software ? software.software : '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedSoftware, setSelectedSoftware] = useState(software);

  // For password strength
  const [pwStrengthLabel, setPwStrengthLabel] = useState('Too weak'); // default

  useEffect(() => {
    const nameParam = searchParams.get('name');
    const emailParam = searchParams.get('email');
    const passwordParam = searchParams.get('password');
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...(nameParam ? { name: nameParam } : {}),
      ...(emailParam ? { email: emailParam } : {}),
      ...(passwordParam ? { password: passwordParam } : {}),
    }));
  }, [searchParams]);

  useEffect(() => {
    if (selectedSoftware) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        software: selectedSoftware.software,
      }));
      localStorage.setItem('software', selectedSoftware.slug);
    }
  }, [selectedSoftware]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // If user is editing "password", check password strength
    if (name === 'password') {
      const strengthCheck = passwordStrength(value);
      setPwStrengthLabel(strengthCheck.value);
    }
  };

  const handleSoftwareSelection = (sw) => {
    setSelectedSoftware(sw);
    setFormData((prevFormData) => ({
      ...prevFormData,
      software: sw.software,
    }));
    localStorage.setItem('software', sw.slug);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (pwStrengthLabel === 'Too weak' || pwStrengthLabel === 'Weak') {
      setErrorMessage(
        'Please pick a stronger password (at least "Medium" strength).'
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const utmParams = {
        ...GetUTMParams(),
      };

      const requestData = {
        ...formData,
        ...utmParams,
        has_installed: localStorage.getItem('has_installed') === 'true',
      };

      requestData.software = selectedSoftware?.slug || '';

      const data = await signup(requestData);  // <-
      if (data.error) {
        throw new Error(data.message || 'Signup failed');
      }

      posthog.register({ distinct_id: data.id });
      localStorage.setItem('software', selectedSoftware?.slug || '');
      const redirUrl = await GetURLRedirect();
      navigate(redirUrl);
    } catch (error) {
      console.error('Signup error:', error.message);
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleError = (err) => {
    setErrorMessage(err);
    setIsSubmitting(false);
  };

  const showLoading = () => {
    setIsSubmitting(true);
    setErrorMessage('');
  };

  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsString = urlParams.toString();

  return (
    <form onSubmit={handleSubmit} className="space-y-5">
      {!selectedSoftware && (
        <SoftwareSelection onSelect={handleSoftwareSelection} />
      )}

      {selectedSoftware && (
        <>
          <div>
            <GoogleSignupButton
              software={selectedSoftware}
              onSubmit={showLoading}
              isSubmitting={isSubmitting}
              onError={handleGoogleError}
            />
          </div>

          <div className="flex items-center w-full mt-8">
            <div className="flex-grow h-px bg-gray-300"></div>
            <p className="mx-4 text-md text-gray-500">Or</p>
            <div className="flex-grow h-px bg-gray-300"></div>
          </div>

          <InputField
            label="Your name"
            id="name"
            name="name"
            type="text"
            placeholder="John Doe"
            autoComplete="name"
            required
            value={formData.name}
            onChange={handleChange}
          />

          <InputField
            label="Email address"
            id="email"
            name="email"
            type="email"
            placeholder="you@yourcompany.com"
            autoComplete="email"
            required
            value={formData.email}
            onChange={handleChange}
          />

          <InputField
            label="Password"
            id="password"
            name="password"
            type="password"
            autoComplete="new-password"
            required
            value={formData.password}
            onChange={handleChange}
            placeholder="At least Medium strength"
          />
          <p className="text-sm text-gray-500 pb-4">
            Password strength: <strong>{pwStrengthLabel}</strong>
            <br />
            <small>
              (8+ characters, 1+ lowercase, 1+ uppercase, 1+ digit, 1+ symbol)
            </small>
          </p>

          <p className="text-sm text-gray-500 pb-2">
            By signing up, you agree to our{' '}
            <a
              href="https://crminputs.com/terms"
              target="_blank"
              className="underline hover:no-underline"
              rel="noreferrer"
            >
              terms
            </a>{' '}
            and{' '}
            <a
              href="https://crminputs.com/privacy"
              target="_blank"
              className="underline hover:no-underline"
              rel="noreferrer"
            >
              privacy policy
            </a>
            .
          </p>

          <div>
            <div className="flex items-center gap-8">
              <button
                type="submit"
                className="w-auto flex justify-center py-4 px-8 rounded-lg hover:shadow-xl text-md font-bold text-white bg-cyan-600 hover:bg-crminputs-dark-blue hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Loader />
                    Loading...
                  </>
                ) : (
                  'Sign up for free'
                )}
              </button>


            </div>


            {errorMessage && (
              <div className="text-red-500 pt-6 rounded relative" role="alert">
                <strong className="font-bold">Error: </strong>
                <span className="block sm:inline">{errorMessage}</span>
              </div>
            )}

            <div className="flex mt-12 gap-x-6">

              <p className="text-md text-gray-500">
                Already have an account?{' '}
                <Link
                  to={`/login?${urlParamsString}`}
                  className="underline hover:no-underline"
                >
                  Login
                </Link>
              </p>
              <p className="text-md text-gray-500">
                <Link
                  to="/forgot-password"
                  className="underline hover:no-underline"
                >
                  Forgot password?
                </Link>
              </p>
            </div>

          </div>
        </>
      )}
    </form>
  );
}

export default SignupForm;
