// File: ./src/api/Pricing.js
import { apiBaseUrl } from '../config';

/**
 * GET /get-coupon?coupon=...
 */
export async function getCoupon(couponCode) {
    const res = await fetch(`${apiBaseUrl}/get-coupon?coupon=${couponCode || ''}`);
    if (!res.ok) {
        throw new Error('Error fetching coupon data');
    }
    return await res.json();
}

/**
 * GET /get-pricing?schedule=monthly|yearly
 */
export async function getPricing(schedule) {
    const url = `${apiBaseUrl}/get-pricing?schedule=${schedule}`;
    const res = await fetch(url);
    if (!res.ok) {
        throw new Error('Error fetching pricing data');
    }
    return await res.json();
}

/**
 * POST /checkout
 */
export async function createCheckoutSession(requestBody) {
    const res = await fetch(`${apiBaseUrl}/checkout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(requestBody),
    });
    if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
    }
    return await res.json(); // { url: ... }
}


/**
 * POST /billing-portal
 */
export async function openBillingPortal() {
    const res = await fetch(`${apiBaseUrl}/billing-portal`, {
        method: 'POST',
        credentials: 'include',
    });
    const data = await res.json();
    if (!res.ok || data.error) {
        throw new Error(data.message || 'Failed to create billing portal session');
    }
    return data; // e.g. { url: '...' }
}

