// File: ./src/components/auth/GoogleLoginButton.js
import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { apiBaseUrl } from '../../config';
import { GetURLRedirect } from '../../utils/Router';
import { GetUTMParams } from '../../utils/UTM';

function GoogleLoginButton({ software, onSubmit, onError, isSubmitting }) {
  const navigate = useNavigate();

  const handleLoginSuccess = async (credentialResponse) => {
    try {
      onSubmit();

      const utmParams = {
        ...GetUTMParams(),
        type: "login",
        has_installed: localStorage.getItem('has_installed') === 'true',
      };

      const requestData = {
        ...utmParams,
        token: credentialResponse.credential,
      };

      if (software?.slug) {
        requestData.software = software.slug;
      }

      const res = await fetch(`${apiBaseUrl}/google-auth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
        credentials: 'include',
      });

      const data = await res.json();

      if (!res.ok) {
        onError(data.message || 'Google login failed');
        return;
      }

      // Successfully logged in => your server sets session cookie
      // We do NOT store 'is_authenticated' in localStorage anymore

      // Possibly store the software if it returned from server
      localStorage.setItem('software', data?.user?.software?.slug || '');

      const redirUrl = await GetURLRedirect(); navigate(redirUrl);
    } catch (error) {
      onError(error.message || 'An error occurred during Google login');
    }
  };

  const handleLoginError = (error) => {
    console.error('Login Failed:', error);
    onError('Google sign in failed. Please try again.');
  };

  return (
    <div style={{ position: 'relative', width: 'fit-content' }}>
      <label className='block text-lg font-default text-gray-800 mb-2'>
        Sign in with Google
      </label>
      <div style={{ position: 'relative', opacity: isSubmitting ? 0.5 : 1 }}>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginError}
          useOneTap={false}
          text='login_with'
          size='large'
          width='100%'
        />
        {isSubmitting && (
          <div
            style={{
              position: 'absolute',
              top: 0, left: 0, right: 0, bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              cursor: 'not-allowed'
            }}
          />
        )}
      </div>
    </div>
  );
}

export default GoogleLoginButton;
