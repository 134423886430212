import React from 'react';
import { useLocation } from 'react-router-dom';
import LinkLogo from './LinkLogo';

const Header = ({ darkMode }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hideHeader = searchParams.get('hide_header') === 'true';
  const appText = searchParams.get('app');
  const softwareText = searchParams.get('software');
  

  if (hideHeader) {
    return null;
  }

  let logo = <>
    <img src={darkMode ? "/img/logo.png" : "/img/logo-dark.png"} alt="CRM Inputs" className="h-6 w-auto" />
    <p className={"text-sm pt-2 italic " + (darkMode ? "text-white" : "text-gray-500")}>LinkedIn Integrations for CRMs.</p>
  </>

  if (appText && softwareText) {
    logo = <LinkLogo app={appText} software={softwareText} darkMode={darkMode} />
  }

  return (
    <header className="flex justify-center items-center pt-16 pb-4 flex-col">
      {logo}
    </header>
  );
};

export default Header;

