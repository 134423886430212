// File: ./src/pages/ConnectPage.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LoaderDark } from '../components/icons/LoaderDark';
import Logo from '../components/header/Logo';
import SignupProgressBar from '../components/signup/SignupProgressBar';
import TrustedBy from '../components/pricing/TrustedBy';
import { GetURLRedirect } from '../utils/Router';
import { getSoftware } from '../api/Software'; // <--- new
import { apiBaseUrl } from '../config';

function ConnectPage() {
  const [software, setSoftware] = useState(null);
  const [isLoadingSoftware, setIsLoadingSoftware] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const navigate = useNavigate();

  console.log('Connect URL:', window.location.href);

  useEffect(() => {
    const softwareSlug = localStorage.getItem('software');
    if (softwareSlug) {
      loadSoftware(softwareSlug);
    } else {
      navigate('/signup');
    }
  }, [navigate]);

  const loadSoftware = async (slug) => {
    setIsLoadingSoftware(true);
    try {
      const data = await getSoftware(slug);
      if (data) {
        setSoftware(data);
      } else {
        console.error('Software not found');
      }
    } catch (err) {
      console.error('Error fetching software:', err);
    } finally {
      setIsLoadingSoftware(false);
    }
  };

  const handleConnect = () => {
    if (software) {
      setIsConnecting(true);
      const clientId = software.oauth_client_id;
      const redirectUri = encodeURIComponent(software.oauth_redirect_uri);
      let authorizationUrl = software.oauth_auth_url.replace('%s', clientId);
      authorizationUrl = authorizationUrl.replace('%s', redirectUri);
      console.log("Redirecting to", authorizationUrl);
      window.open(authorizationUrl, '_blank');
    }
  };

  const fetchAccessToken = async () => {
    setIsConnecting(true);
    const code = localStorage.getItem('oauth_code');
    try {
      let url = `${apiBaseUrl}/access-token`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ oauth_code: code })
      });
      const data = await response.json();
      if (data.error) {
        setErrorMessage(data.message);
        setIsConnecting(false);
      } else {
        // store all returned keys in localStorage
        Object.keys(data.data).forEach(key => {
          localStorage.setItem("oauth_" + key, data.data[key]);
        });
        const redirect = await GetURLRedirect();
        navigate(redirect);
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
      setIsConnecting(false);
    }
  };

  useEffect(() => {
    const code = localStorage.getItem('oauth_code');
    if (code) {
      fetchAccessToken();
    }
  }, []);

  return (
    <div className='sm:min-h-screen sm:flex'>
      {/* Left column */}
      <div className='flex-1 flex flex-col justify-between bg-white'>
        {isLoadingSoftware ? (
          <div className='flex justify-center items-center h-full'>
            <LoaderDark />
          </div>
        ) : (
          <>
            <div className='py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full'>
              <Logo loading={isLoadingSoftware} software={software} />
              <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                Connect to {software?.software}
              </h1>
              {!isConnected ? (
                <>
                  <p className='mt-4'>
                    Please connect your {software?.software} account so we can start syncing your LinkedIn data.
                  </p>
                  <div className='flex justify-between items-center mt-12'>
                    <button
                      onClick={handleConnect}
                      className='w-auto flex justify-center py-3 px-6 rounded-lg hover:shadow-xl text-md font-bold text-white bg-cyan-600 hover:bg-crminputs-dark-blue hover:text-white disabled:opacity-50 disabled:cursor-not-allowed'
                    >
                      Connect...
                    </button>

                    <Link
                      to='/complete'
                      className='ml-4 w-auto flex justify-center py-3 px-6 rounded-lg text-md font-bold text-cyan-600 hover:text-black'
                    >
                      Skip
                    </Link>
                  </div>
                  {errorMessage && (
                    <div className='text-red-500 py-8 rounded relative' role='alert'>
                      <strong className='font-bold'>Error: </strong>
                      <span className='block sm:inline'>{errorMessage}</span>
                    </div>
                  )}
                </>
              ) : (
                <p className='mt-4'>Successfully connected. Redirecting...</p>
              )}
              <div className="p-32">
                <SignupProgressBar currentStep={2} />
              </div>
            </div>
          </>
        )}
      </div>

      {/* Right column */}
      <div className='flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue flex-col px-8 py-16 sm:p-16'>
        <h3 className='text-white text-4xl font-bold pb-10'>Stop doing CRM data entry.</h3>
        <img
          className='max-w-full sm:max-w-sm object-contain'
          src='/img/signup-hero.jpg'
          alt='CRM Inputs - LinkedIn Integrations for CRMs'
        />
        <h3 className='text-cyan-200 italic text-xl sm:text-2xl font-default pt-8 pb-16'>
          1-Click LinkedIn to CRM integration ✨
        </h3>
        <TrustedBy darkMode={true} />
      </div>
    </div>
  );
}

export default ConnectPage;
