// File: ./src/api/User.js
import { apiBaseUrl } from '../config';

/**
 * GET /get-user
 */
export async function getUser() {
  try {
    const res = await fetch(`${apiBaseUrl}/get-user`, {
      method: 'GET',
      credentials: 'include'
    });
    if (!res.ok) {
      return null;
    }
    const data = await res.json();
    if (data.error) {
      return null;
    }
    return data;
  } catch (error) {
    console.error('Error calling /get-user:', error);
    return null;
  }
}

/**
 * setUserBoolean(key, value)
 */
export async function setUserBoolean(key, value) {
  try {
    const res = await fetch(`${apiBaseUrl}/set-user-boolean`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ key, value }),
    });
    if (!res.ok) {
      const data = await res.json().catch(() => ({}));
      throw new Error(data.message || 'Failed to set user boolean');
    }
  } catch (error) {
    console.error('Error calling /set-user-boolean:', error);
    throw error;
  }
}
