// File: ./src/api/Auth.js
import { apiBaseUrl } from '../config';

/**
 * googleAuth(credential, options)
 * - Used in GoogleSignupButton / GoogleLoginButton.
 *   => POST /google-auth
 */
export async function googleAuth(credential, { type, software, utmParams, has_installed }) {
  const requestData = {
    ...utmParams,
    token: credential,
    software,
    type, // "signup" or "login"
    has_installed,
  };
  const res = await fetch(`${apiBaseUrl}/google-auth`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(requestData),
  });
  const data = await res.json();
  if (!res.ok) {
    throw new Error(data.message || 'Google auth failed');
  }
  return data;
}

/**
 * signup(formData)
 * - used in SignupForm to POST /signup
 */
export async function signup(formData) {
  const res = await fetch(`${apiBaseUrl}/signup`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });
  const data = await res.json();
  if (!res.ok || data.error) {
    throw new Error(data.message || 'Signup failed');
  }
  return data; // e.g. { user: { ... }, ... }
}

/**
 * login(formData)
 * - used in LoginForm to POST /login
 */
export async function login(formData) {
  const res = await fetch(`${apiBaseUrl}/login`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });
  const data = await res.json();
  if (!res.ok || data.error) {
    throw new Error(data.message || 'Login failed');
  }
  return data; // e.g. { user: { ... }, ... }
}

/**
 * forgotPassword(email)
 * - POST /forgot-password
 */
export async function forgotPassword(email) {
  const res = await fetch(`${apiBaseUrl}/forgot-password`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ email }),
  });
  const data = await res.json();
  if (!res.ok || data.error) {
    throw new Error(data.message || 'Something went wrong');
  }
  return data;
}

/**
 * resetPassword(token, newPassword)
 * - POST /reset-password
 */
export async function resetPassword(token, newPassword) {
  const res = await fetch(`${apiBaseUrl}/reset-password`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ token, new_password: newPassword }),
  });
  const data = await res.json();
  if (!res.ok || data.error) {
    throw new Error(data.message || 'Error resetting password');
  }
  return data;
}

/**
 * logout()
 * - POST /logout
 */
export async function logout() {
  await fetch(`${apiBaseUrl}/logout`, {
    method: 'POST',
    credentials: 'include',
  });
  // no return data needed
}

/**
 * isLoggedIn()
 * - GET /is-logged-in
 * - returns true/false
 */
export async function isLoggedIn() {
  try {
    const res = await fetch(`${apiBaseUrl}/is-logged-in`, {
      method: 'GET',
      credentials: 'include',
    });
    if (!res.ok) return false;
    const data = await res.json();
    return data.is_logged_in === true;
  } catch (error) {
    console.error('Error calling /is-logged-in:', error);
    return false;
  }
}
