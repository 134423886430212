// File: ./src/pages/ResetPasswordPage.js
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Logo from '../components/header/Logo';
import TrustedBy from '../components/pricing/TrustedBy';
import { apiBaseUrl } from '../config';
import { LoaderDark } from '../components/icons/LoaderDark';

// ADD:
import { passwordStrength } from 'check-password-strength';
import { resetPassword } from '../api/Auth';

function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  const navigate = useNavigate();

  const [isVerifying, setIsVerifying] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [done, setDone] = useState(false);

  // for displaying local password strength
  const [pwStrengthLabel, setPwStrengthLabel] = useState('Too weak');

  useEffect(() => {
    // If no token, show error
    if (!token) {
      setErrorMessage('No reset token provided.');
    }
    setIsVerifying(false);
  }, [token]);

  // handle password changes
  const handlePasswordChange = (e) => {
    const val = e.target.value;
    setNewPassword(val);
    const strengthCheck = passwordStrength(val);
    setPwStrengthLabel(strengthCheck.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');

    if (!token) {
      setErrorMessage('No reset token provided.');
      return;
    }

    // disallow if "Weak" or "Too weak"
    if (pwStrengthLabel === 'Weak' || pwStrengthLabel === 'Too weak') {
      setErrorMessage('Please pick a stronger password (at least Medium) - requires at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 digit, and 1 symbol.');
      return;
    }

    setIsSubmitting(true);
    try {
      const data = await resetPassword(token, newPassword);
      // success => user is now logged in
      setDone(true);
      if (data.data && data.data.redirect_url) {
        setTimeout(() => {
          navigate(data.data.redirect_url);
        }, 1500);
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsSubmitting(false);
    }
  }

  const title = "Reset Password - CRM Inputs";
  const description = "Set your new password.";

  if (isVerifying) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <div className="min-h-screen flex">
          {/* Left column */}
          <div className="flex-1 flex flex-col justify-between bg-white">
            <div className="py-16 px-8">
              <Logo />
              <div className="flex justify-center items-center mt-20">
                <LoaderDark />
                <p className="ml-4">Checking token...</p>
              </div>
            </div>
          </div>
          {/* Right column */}
          <div className="flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue 
                          flex-col px-8 py-16">
            <h3 className="text-white text-4xl font-bold pb-10">
              Stop doing CRM data entry.
            </h3>
            <img
              className="max-w-full sm:max-w-sm object-contain"
              src="/img/signup-hero.jpg"
              alt="CRM Inputs - LinkedIn Integrations for CRMs"
            />
            <h3 className="text-cyan-200 italic text-xl sm:text-2xl font-default pt-8 pb-16">
              1-Click LinkedIn to CRM integration ✨
            </h3>
            <TrustedBy darkMode={true} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <div className="min-h-screen flex">
        {/* Left column */}
        <div className="flex-1 flex flex-col justify-between bg-white">
          <div className="py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full">
            <Logo />
            <h2 className="mt-10 sm:mt-20 text-3xl font-extrabold text-black">
              Reset Password
            </h2>
            {errorMessage && (
              <div className="text-red-500 py-4" role="alert">
                <strong>Error: </strong>
                <span>{errorMessage}</span>
              </div>
            )}
            {done ? (
              <div className="mt-8">
                <p className="text-md text-gray-700 pb-8">
                  Success! Your password has been updated. Redirecting...
                </p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                <div>
                  <label
                    htmlFor="new-password"
                    className="block text-lg font-default text-gray-800"
                  >
                    New Password
                  </label>
                  <div className="mt-1.5">
                    <input
                      id="new-password"
                      name="new_password"
                      type="password"
                      required
                      placeholder="At least Medium strength"
                      value={newPassword}
                      onChange={handlePasswordChange}
                      className="appearance-none block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm 
                                 placeholder-gray-400 focus:outline-none focus:ring-crminputs-dark-blue 
                                 focus:border-crminputs-darkish-blue sm:text-sm"
                    />
                  </div>
                  <p className="text-sm text-gray-500 pt-4">
                    Password strength: <strong>{pwStrengthLabel}</strong>

                    <br />
                    <small>
                      (8+ characters, 1+ lowercase letter, 1+ uppercase letter, 1+ digit, and 1+ symbol)
                    </small>
                  </p>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-auto flex justify-center py-4 px-8 rounded-lg hover:shadow-xl text-md font-bold text-white 
                               bg-cyan-600 hover:bg-crminputs-dark-blue hover:text-white disabled:opacity-50 
                               disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? (
                      <>
                        <LoaderDark />
                        Saving...
                      </>
                    ) : (
                      'Set New Password'
                    )}
                  </button>


                </div>

              </form>
            )}
          </div>
        </div>

        {/* Right column */}
        <div className="flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue 
                        flex-col px-8 py-16 sm:p-16">
          <h3 className="text-white text-4xl font-bold pb-10">
            Stop doing CRM data entry.
          </h3>
          <img
            className="max-w-full sm:max-w-sm object-contain"
            src="/img/signup-hero.jpg"
            alt="CRM Inputs - LinkedIn Integrations for CRMs"
          />
          <h3 className="text-cyan-200 italic text-xl sm:text-2xl font-default pt-8 pb-16">
            1-Click LinkedIn to CRM integration ✨
          </h3>

          <TrustedBy darkMode={true} />
        </div>
      </div>
    </>
  );
}

export default ResetPasswordPage;
