// File: ./src/pages/CallbackPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OAUTH_KEYS } from '../constant';
import { isLoggedIn } from '../api/Auth';

function CallbackPage() {
    const navigate = useNavigate();

    useEffect(() => {
        let fragmentParams = new URLSearchParams(window.location.hash.substring(1));

        console.log('Callback URL:', window.location.href);

        // Save all relevant fragment params to localStorage
        let fragString = '';
        OAUTH_KEYS.concat(['software']).forEach(key => {
            if (fragmentParams.get(key)) {
                localStorage.setItem(key, fragmentParams.get(key));
            }
            if (localStorage.getItem(key)) {
                fragString += key + '=' + localStorage.getItem(key) + '&';
            }
        });

        // Now check if user is logged in via /is-logged-in
        (async () => {
          const loggedIn = await isLoggedIn();
          if (loggedIn) {
            // If logged in, go to /connected#...
            console.log('Connected, redirecting to /connected page');
            console.log('Fragment string:', fragString);
            navigate('/connected#' + fragString);
          } else {
            // If NOT logged in, maybe we direct them to /connect
            // or if your flow says they must re-login, do /login, etc.
            console.log('Not logged in. Redirecting to /connect');
            navigate('/auth');
          }
        })();
    }, [navigate]);

    // Return empty div while redirect
    return <div></div>;
}

export default CallbackPage;
