// File: ./src/api/Verify.js
import { apiBaseUrl } from '../config';

/**
 * verifySettings(token, refreshToken)
 * => POST /verify-settings
 */
export async function verifySettings(apiKey1, apiKey2) {
    const body = { api_key_1: apiKey1, api_key_2: apiKey2 };
    const res = await fetch(`${apiBaseUrl}/verify-settings`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    });
    const data = await res.json();
    if (!res.ok || data.error) {
        throw new Error(data.message || 'Error verifying settings');
    }
    return data; // e.g. { error: false, message: 'ok' }
}
