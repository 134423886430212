// File: ./src/pages/LoginPage.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Logo from '../components/header/Logo';
import LoginForm from '../components/login/LoginForm';
import TrustedBy from '../components/pricing/TrustedBy';
import { LoaderDark } from '../components/icons/LoaderDark';
import { OAUTH_KEYS } from '../constant';
import { getSoftware } from '../api/Software'; // from /api/Software
import { useNavigate } from 'react-router-dom';
import { apiBaseUrl } from '../config';
import { isLoggedIn } from '../api/Auth';

function LoginPage() {
  const title = "Login - CRM Inputs";
  const description = "Create your account and start optimizing your CRM workflow today.";

  const urlParams = new URLSearchParams(window.location.search);
  const reauth = urlParams.get('reauth') === 'true';
  const softwareSlug = localStorage.getItem('software');

  const [software, setSoftware] = useState(null);
  const [isLoadingSoftware, setIsLoadingSoftware] = useState(false);

  const navigate = useNavigate();

  console.log('Login URL:', window.location.href);

  useEffect(() => {
    // If reauth => do logout
    if (reauth) {
      fetch(`${apiBaseUrl}/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      OAUTH_KEYS.forEach(key => {
        localStorage.removeItem(key);
      });
    } else {
      // Otherwise, if user is already logged in => go to /dashboard
      isLoggedIn().then((logged) => {
        console.log("Logged in:", logged);
        if (logged) {
          navigate('/dashboard');
        }
      });
    }


    if (softwareSlug) {
      loadSoftware(softwareSlug);
    }
  }, [reauth, navigate, softwareSlug]);

  const loadSoftware = async (slug) => {
    setIsLoadingSoftware(true);
    try {
      const data = await getSoftware(slug);
      if (data) {
        setSoftware(data);
        localStorage.setItem('is_oauth', data.is_oauth === true);
      } else {
        console.error('Software not found');
      }
    } catch (error) {
      console.error('Error fetching software:', error);
    } finally {
      setIsLoadingSoftware(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.crminputs.io/login" />
      </Helmet>

      <div className="min-h-screen flex">
        {/* Left column */}
        <div className="flex-1 flex flex-col justify-between bg-white">
          {isLoadingSoftware ? (
            <div className='flex justify-center items-center h-full'>
              <LoaderDark />
            </div>
          ) : (
            <>
              <div className="py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full">
                <Logo loading={isLoadingSoftware} software={software} />
                <h2 className="mt-10 sm:mt-20 text-3xl font-extrabold text-black">
                  {reauth ? "Session expired, please login again." : "Login"}
                </h2>
                <div className="mt-8">
                  <LoginForm software={software} />
                </div>
              </div>
            </>
          )}
        </div>

        {/* Right column */}
        <div className="flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue flex-col px-8 py-16 sm:p-16">
          <h3 className="text-white text-4xl font-bold pb-10">
            Stop doing CRM data entry.
          </h3>
          <img
            className="max-w-full sm:max-w-sm object-contain"
            src="/img/signup-hero.jpg"
            alt="CRM Inputs - LinkedIn Integrations for CRMs"
          />
          <h3 className="text-cyan-200 italic text-xl sm:text-2xl font-default pt-8 pb-16">
            1-Click LinkedIn to CRM integration ✨
          </h3>

          <TrustedBy darkMode={true} />
        </div>
      </div>
    </>
  );
}

export default LoginPage;
