// File: ./src/api/Software.js
import { apiBaseUrl } from '../config';

export async function getSoftware(softwareSlug) {
  const url = `${apiBaseUrl}/get-software?software=${encodeURIComponent(softwareSlug)}`;
  const res = await fetch(url);
  if (!res.ok) {
    return null;
  }
  const data = await res.json();
  if (!data || !data.slug) {
    return null;
  }
  return data;
}

export async function getSoftwareOptions() {
  const url = `${apiBaseUrl}/get-software-options`;
  const res = await fetch(url);
  if (!res.ok) {
    throw new Error('Failed to fetch software options');
  }
  return await res.json();
}
