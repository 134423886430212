// File: ./src/components/login/LoginForm.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { apiBaseUrl } from '../../config';
import InputField from '../fields/InputField';
import GoogleLoginButton from '../auth/GoogleLoginButton';
import { GetURLRedirect } from '../../utils/Router';
import { Loader } from '../icons/Loader';
import { login } from '../../api/Auth';

function LoginForm({ software }) {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    software: software && software.slug ? software.slug : '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);

    try {
      const data = await login(formData);
      if (data.error) {
        throw new Error(data.message || 'Login failed');
      }

      localStorage.setItem('software', software?.slug || '');
      const redirUrl = await GetURLRedirect();
      navigate(redirUrl);
    } catch (error) {
      console.error('Login error:', error.message);
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleError = (error) => {
    setErrorMessage(error);
    setIsSubmitting(false);
  };

  const showLoading = () => {
    setIsSubmitting(true);
    setErrorMessage('');
  };

  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsString = urlParams.toString();

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {errorMessage && (
        <div className="text-red-500 py-4 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}

      <div>
        <GoogleLoginButton
          software={software}
          onSubmit={showLoading}
          isSubmitting={isSubmitting}
          onError={handleGoogleError}
        />
      </div>

      <div className="flex items-center w-full mt-8">
        <div className="flex-grow h-px bg-gray-300"></div>
        <p className="mx-4 text-md text-gray-500">Or</p>
        <div className="flex-grow h-px bg-gray-300"></div>
      </div>

      <InputField
        label="Email address"
        id="email"
        name="email"
        type="email"
        placeholder="you@yourcompany.com"
        autoComplete="email"
        required
        value={formData.email}
        onChange={handleChange}
      />
      <InputField
        label="Password"
        id="password"
        name="password"
        type="password"
        autoComplete="current-password"
        required
        value={formData.password}
        onChange={handleChange}
      />
      <div>
        <div className='flex items-center gap-8'>
          <button
            type="submit"
            className="w-auto flex justify-center py-4 px-8 rounded-lg hover:shadow-xl text-md font-bold text-white bg-cyan-600 hover:bg-crminputs-dark-blue hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader />
                Loading...
              </>
            ) : (
              'Login'
            )}
          </button>



        </div>
        <div className="flex items-center gap-8 pt-12">

          <p className="text-md text-gray-500">
            Don't have an account?{' '}
            <Link to={`/signup?${urlParamsString}`} className="underline hover:no-underline">
              Sign up
            </Link>
          </p>
          <p className="text-md text-gray-500">
            <Link to="/forgot-password" className="underline hover:no-underline">
              Forgot password?
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
}

export default LoginForm;
