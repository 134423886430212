// ConnectedPage.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TrustedBy from '../components/pricing/TrustedBy';
import { apiBaseUrl } from '../config';
import Logo from '../components/header/Logo';
import { LoaderDark } from '../components/icons/LoaderDark';
import { getSoftware } from '../api/Software';
import { verifySettings } from '../api/Verify';

function ConnectedPage() {
    const navigate = useNavigate();
    const [software, setSoftware] = useState(null);
    const [isLoadingSoftware, setIsLoadingSoftware] = useState(true);
    const [verified, setVerified] = useState(false);
    const [verifying, setVerifying] = useState(false);
    const [error, setError] = useState(null);

    console.log('Connected URL:', window.location.href);

    useEffect(() => {
        const softwareSlug = localStorage.getItem('software');
        if (softwareSlug) {
            fetchSoftware(softwareSlug);
        } else {
            setError('No software found in localStorage');
        }
    }, []);

    const fetchSoftware = async (softwareSlug) => {
        setIsLoadingSoftware(true);
        try {
            const data = await getSoftware(softwareSlug);
            if (data) {
                setSoftware(data);
            } else {
                setError('Software not found');
            }
        } catch (error) {
            setError('Error fetching software: ' + (error.message || error));
        } finally {
            setIsLoadingSoftware(false);
        }
    };

    const verifySettingsCall = async (token, refreshToken) => {
        setVerifying(true);
        try {
            await verifySettings(token, refreshToken);
            setVerified(true);
            setTimeout(() => {
                navigate('/complete');
            }, 3000);
        } catch (err) {
            setError('Error verifying settings: ' + (err.message || err));
        } finally {
            setVerifying(false);
        }
    };


    useEffect(() => {
        const token = localStorage.getItem('oauth_access_token');
        const refreshToken = localStorage.getItem('oauth_refresh_token');
        if (token) verifySettingsCall(token, refreshToken);
    }, []);



    const renderContent = () => {
        if (isLoadingSoftware) {
            return (
                <div className='flex justify-center items-center h-full'>
                    <LoaderDark />
                </div>
            );
        }

        if (error) {
            return (
                <>
                    <Logo loading={isLoadingSoftware} software={software} />
                    <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                        Connection Error.
                    </h1>
                    <p className='mt-4 text-red-500'>{error}</p>
                    <Link to='/connect' className='mt-6 inline-flex justify-center py-4 px-8 rounded-lg hover:shadow-xl text-md font-bold text-white bg-cyan-600 hover:bg-crminputs-dark-blue hover:text-white'>
                        Try again
                    </Link>
                </>
            );
        }

        if (verifying && !verified) {
            return (
                <>
                    <Logo loading={isLoadingSoftware} software={software} />
                    <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                        Testing connection.
                    </h1>
                    <p className='mt-4'>Just a sec...</p>
                </>
            );
        }

        if (verified && software) {
            return (
                <>
                    <Logo loading={isLoadingSoftware} software={software} />
                    <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                        Successfully connected.
                    </h1>
                    <p className='mt-4'>
                        You've successfully connected to {software.software}. Redirecting...
                    </p>
                </>
            );
        }

        // Default fallback if we have software but haven't verified yet
        return (
            <>
                <Logo loading={isLoadingSoftware} software={software} />
                <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                    Connecting...
                </h1>
                <p className='mt-4'>Please wait.</p>
            </>
        );
    };

    return (
        <div className='sm:min-h-screen sm:flex'>
            <div className='flex-1 flex flex-col justify-between bg-white'>


                {isLoadingSoftware ? (
                    <div className='flex justify-center items-center h-full'>
                        <LoaderDark />
                    </div>
                ) :
                    <>
                        <div className='py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full'>
                            {renderContent()}
                        </div>
                    </>
                }

            </div>
            <div className='flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue flex-col px-8 py-16 sm:p-16'>
                <h3 className='text-white text-4xl font-bold pb-10'>Stop doing CRM data entry.</h3>
                <img
                    className='max-w-full sm:max-w-sm object-contain'
                    src='/img/signup-hero.jpg'
                    alt='CRM Inputs - LinkedIn Integrations for CRMs'
                />
                <h3 className='text-cyan-200 italic text-xl sm:text-2xl font-default pt-8 pb-16'>
                    1-Click LinkedIn to CRM integration ✨
                </h3>
                <TrustedBy darkMode={true} />
            </div>
        </div>
    );
}

export default ConnectedPage;
