import { GoogleOAuthProvider } from '@react-oauth/google';
import posthog from 'posthog-js';
import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { googleClientId } from './config'; // Ensure the path to your config is correct
import Layout from './Layout';
import AuthPage from './pages/AuthPage';
import CompletePage from './pages/CompletePage';
import ConnectPage from './pages/ConnectPage';
import InstalledPage from './pages/InstalledPage';
import InstallPage from './pages/InstallPage';
import LoginPage from './pages/LoginPage';
import PricingPage from './pages/PricingPage';
import SignupPage from './pages/SignupPage';
import LogoutPage from './pages/LogoutPage';
import ConnectedPage from './pages/ConnectedPage';
import CallbackPage from './pages/CallbackPage';
import UninstalledPage from './pages/UninstalledPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import DashboardPage from './pages/DashboardPage';
import { GetUTMParams } from './utils/UTM';

// Initialize PostHog
posthog.init('phc_1NVntEGdFhoz8jfYK78qcQyaVWNENHrO8EAomucpT3V', {
  api_host: 'https://eu.i.posthog.com',
  person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
});

function App() {
  // Add this helper function inside App component
  const getQueryParams = () => {
    return Object.fromEntries(new URLSearchParams(window.location.search));
  };

  useEffect(() => {
    // Function to check if any UTM parameters are stored
    function areUTMParamsStored() {
      return ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].some((param) => {
        return localStorage.getItem(param) !== null;
      });
    }

    // Get UTM parameters from the URL
    const params = getQueryParams();

    // Check if UTM parameters exist in the URL
    const hasUTMParamsInURL = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].some(
      (param) => params[param]
    );

    // Only store UTM parameters if they are not already stored and exist in the URL
    if (!areUTMParamsStored() && hasUTMParamsInURL) {
      // Store UTM parameters in localStorage
      ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach((param) => {
        if (params[param]) {
          localStorage.setItem(param, params[param]);
        }
      });
    }

    // Register UTM parameters with PostHog
    posthog.register({
      ...GetUTMParams(),
    });


  }, []);

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Router>
        <Layout>
          <Routes>
            {/* Cant visit these pages if logged in */}
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />

            {/* Our new dashboard route */}
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/install" element={<InstallPage />} />
            <Route path="/installed" element={<InstalledPage />} />
            <Route path="/complete" element={<CompletePage />} />
            <Route path="/callback" element={<CallbackPage />} />
            <Route path="/connected" element={<ConnectedPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/connect" element={<ConnectPage />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/uninstalled" element={<UninstalledPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/pricing" element={<PricingPage />} />
          </Routes>
        </Layout>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
