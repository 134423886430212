// File: ./src/components/auth/GoogleSignupButton.js
import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { apiBaseUrl } from '../../config';
import { GetURLRedirect } from '../../utils/Router';
import posthog from 'posthog-js';
import { GetUTMParams } from '../../utils/UTM';
import { googleAuth } from '../../api/Auth';

function GoogleSignupButton({ software, onSubmit, isSubmitting, onError }) {
  const navigate = useNavigate();

  const handleLoginSuccess = async (credentialResponse) => {
    try {
      onSubmit();
      const utmParams = GetUTMParams();
      // pass in object to googleAuth
      const submitParams = {
        ...utmParams,
        type: 'signup',
        software: software?.slug,
        has_installed: localStorage.getItem('has_installed') == 'true',
      };

      console.log('submitParams', submitParams);

      const data = await googleAuth(credentialResponse.credential, submitParams);

      // data => the response from server
      posthog.register({ distinct_id: data.id });
      
      const redirUrl = await GetURLRedirect();
      navigate(redirUrl);
    } catch (error) {
      onError(error.message || 'An error occurred during Google signup');
    }
  };

  const handleLoginError = (error) => {
    console.error('Login Failed:', error);
    onError('Google sign in failed. Please try again.');
  };

  return (
    <div style={{ position: 'relative', width: 'fit-content' }}>
      <label className='block text-lg font-default text-gray-800 mb-2'>
        Sign up with Google
      </label>
      <div style={{ position: 'relative', opacity: isSubmitting ? 0.5 : 1 }}>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginError}
          useOneTap={false}
          text='signup_with'
          size='large'
          width='100%'
        />
        {isSubmitting && (
          <div
            style={{
              position: 'absolute',
              top: 0, left: 0, right: 0, bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              cursor: 'not-allowed'
            }}
          />
        )}
      </div>
    </div>
  );
}

export default GoogleSignupButton;
