// File: ./src/utils/Router.js
import { getUser } from '../api/User';

/**
 * Because we need to check user.has_installed from the DB, this function is async.
 */
export async function GetURLRedirect() {
    const user = await getUser();
    let urlParams = new URLSearchParams(window.location.search);
    let urlParamsString = urlParams.toString();
    if (urlParamsString) {
        urlParamsString = '?' + urlParamsString;
    }

    // If no user => not logged in => /signup
    if (!user) {
        console.log("Redirecting to /signup");
        return '/signup' + urlParamsString;
    }

    const hasInstalled = !!user.has_installed;
    const isOauth = localStorage.getItem('is_oauth') === 'true';
    const hasConnected = localStorage.getItem('oauth_access_token') != null;

    if (hasInstalled) {
        if (isOauth && !hasConnected) {
            console.log("Redirecting to /connect");
            return '/connect' + urlParamsString;
        } else if (isOauth && hasConnected) {
            let fragUrl = new URLSearchParams();
            Object.keys(localStorage).forEach(key => {
                if (key.startsWith('oauth_')) {
                    fragUrl.set(key, localStorage.getItem(key));
                }
            });
            console.log("Redirecting to /connected#" + fragUrl.toString());
            return '/connected#' + fragUrl.toString();
        } else {
            console.log("Redirecting to /complete");
            return '/complete' + urlParamsString;
        }
    } else {
        console.log("Redirecting to /install");
        return '/install' + urlParamsString;
    }
}
